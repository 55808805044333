<template>
  <span class="relative z-0 inline-flex">
    <button
      type="button"
      :class="[layout === 'list' ? 'bg-white shadow-sm' : 'hover:bg-white dark:hover:bg-gray-700 bg-transparent hover:shadow-sm']"
      class="text-gray-400 border border-gray-300 dark:border-gray-700 p-1.5 rounded-l-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-100"
      @click.prevent="useListView"
    >
      <fa-icon
        icon="list"
        class="flex-shrink-0 text-gray-500 fa-fw"
      />
      <span class="sr-only">Use list view</span>
    </button>
    <button
      type=" button"
      :class="[layout === 'card' ? 'bg-white dark:bg-neutral-900 shadow-sm' : 'hover:bg-white dark:hover:bg-gray-700 bg-transparent hover:shadow-sm']"
      class="text-gray-400 border border-gray-300 dark:border-gray-700 border-l-0 p-1.5 rounded-r-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-100"
      @click.prevent="useGridView"
    >
      <fa-icon
        icon="border-all"
        class="flex-shrink-0 text-gray-500 fa-fw"
      />
      <span class="sr-only">Use grid view</span>
    </button>
  </span>
</template>
<script>

export default {
  name: 'AssetsExplorerToolbarFilters',

  props: {
    layout: {
      required: true,
      type: String
    }
  },

  methods: {
    toggleLayout() {
      if (this.layout === 'card') this.$emit('layout-change', 'list')
      if (this.layout === 'list') this.$emit('layout-change', 'card')
    },

    useListView() {
      this.$emit('layout-change', 'list')
    },
    useGridView() {
      this.$emit('layout-change', 'card')
    }

  }
}
</script>
